import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ProgressBar from '../Animations/ProgressBar';
import { Palette } from '../constants';
import routePath from '../core/router/routePath';

function SplashScreen() {
  const [showCover, setShowCover] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowCover(false);
    }, 2000);
  }, []);
  return (
    <>
      <Container isVisible={showLoader}>
        <NameText>Stephanie Loureiro</NameText>
        <ProgressBar />
      </Container>
      <PinkCover isVisible={showCover} />
      <SecondCover
        isVisible={!showCover}
        isWhite={
          pathname.includes(routePath.WORK) ||
          pathname.includes(routePath.PRESS)
        }
      />
    </>
  );
}

const SecondCover = styled.div`
  width: 100%;
  height: 100vh;
  background: ${(props) => (props.isWhite ? Palette.WHITE : Palette.BLACK)};
  z-index: 1;
  position: fixed;
  transition: opacity 1.5s;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

const PinkCover = styled.div`
  width: 100%;
  height: 100vh;
  background: #eac4fc;
  z-index: 2;
  transition: transform 1s;
  transform: translateY(${(props) => (props.isVisible ? '0%' : '-100%')});
  position: fixed;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: poppins;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 3;
  position: fixed;
  background: #f5f5f5;
  transition: transform 0.5s;
  transform: translateY(${(props) => (props.isVisible ? '0%' : '-100%')});
  @media (max-width: 765px) {
    padding: 0 10px;
  }
`;

const NameText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 178px;
  margin-right: 20px;
  @media (max-width: 765px) {
    font-size: 12px;
    width: max-content;
    margin-right: 10px;
    white-space: nowrap;
  }
`;

export default SplashScreen;
