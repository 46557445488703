import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

import { MediaQuery } from '../constants';

function ProgressBar() {
  const progressRef = useRef(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const isMobile = window.innerWidth < 765;

  useEffect(() => {
    const progressBar = progressRef.current;
    const progressBarWidth = isMobile ? 170 : 198;

    gsap.to(progressBar, {
      width: '100%',
      duration: 1.5,
      onUpdate: () => {
        const totalWidth = progressRef.current.offsetWidth;
        const percent = (totalWidth / progressBarWidth) * 100;
        setProgressPercent(`${Math.round(percent)}%`);
      },
    });
  }, []);
  return (
    <Container>
      <ProgressBarWrapper>
        <ProgressBarElement ref={progressRef} />
      </ProgressBarWrapper>
      <ProgressLabel>{progressPercent}</ProgressLabel>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  @media ${MediaQuery.tablet} {
    height: 100dvh;
  }
`;

const ProgressBarWrapper = styled.div`
  width: 198px;
  height: 16px;
  border-radius: 20px;
  background-color: white;

  @media (max-width: 765px) {
    width: 170px;
    height: 10px;
  }
`;

const ProgressBarElement = styled.div`
  height: 100%;
  background-color: #eac4fc;
  width: 0%;
  border-radius: 20px;
`;

const ProgressLabel = styled.div`
  right: 5px;
  color: #000;
  font-weight: 600;
  margin-left: 20px;
  width: 32px;

  @media (max-width: 765px) {
    margin-left: 12px;
    font-size: 12px;
  }
`;

export default ProgressBar;
